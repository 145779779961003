.internal-motive-component-module__ZWYt7W__m-container {
  background-color: #fff;
}

.internal-motive-component-module__ZWYt7W__m-container .internal-motive-component-module__ZWYt7W__gcontainer {
  text-align: center;
}

.motive-component-module__LPaneq__external-motives {
  box-sizing: content-box;
  border-top: 10px solid rgba(67, 67, 67, 0);
  border-bottom: 10px solid rgba(67, 67, 67, 0);
  position: relative;
}

.motive-component-module__LPaneq__external-motives .motive-component-module__LPaneq__motive {
  text-align: center;
}

.motive-component-module__LPaneq__external-motives .motive-component-module__LPaneq__gcontainer {
  text-align: center;
  margin: 0 auto;
}

.motive-component-module__LPaneq__external-motives .motive-component-module__LPaneq__width_major, .motive-component-module__LPaneq__external-motives .motive-component-module__LPaneq__width_major_md {
  text-align: center;
  min-width: 100%;
  width: 350px !important;
  height: 100px !important;
}

.motive-component-module__LPaneq__external-motives .motive-component-module__LPaneq__height_major {
  text-align: center;
  min-width: 100%;
  height: 600px !important;
}

@media (min-width: 500px) {
  .motive-component-module__LPaneq__external-motives .motive-component-module__LPaneq__width_major {
    width: 468px !important;
    height: 60px !important;
  }

  .motive-component-module__LPaneq__external-motives .motive-component-module__LPaneq__width_major_md {
    width: 350px !important;
    height: 60px !important;
  }
}

@media (min-width: 728px) {
  .motive-component-module__LPaneq__external-motives .motive-component-module__LPaneq__width_major {
    width: 728px !important;
    height: 90px !important;
  }

  .motive-component-module__LPaneq__external-motives .motive-component-module__LPaneq__width_major_md {
    width: 468px !important;
    height: 60px !important;
  }
}

@media (min-width: 900px) {
  .motive-component-module__LPaneq__external-motives .motive-component-module__LPaneq__width_major {
    width: 900px !important;
    height: 120px !important;
  }

  .motive-component-module__LPaneq__external-motives .motive-component-module__LPaneq__width_major_md {
    width: 728px !important;
    height: 90px !important;
  }
}

@media (min-width: 938px) {
  .motive-component-module__LPaneq__external-motives .motive-component-module__LPaneq__height_major {
    height: 600px !important;
  }
}

@media (min-width: 1200px) {
  .motive-component-module__LPaneq__external-motives .motive-component-module__LPaneq__width_major {
    width: 1200px !important;
    height: 120px !important;
  }

  .motive-component-module__LPaneq__external-motives .motive-component-module__LPaneq__width_major_md {
    width: 900px !important;
    height: 120px !important;
  }
}

@media (min-width: 1350px) {
  .motive-component-module__LPaneq__external-motives .motive-component-module__LPaneq__height_major {
    height: 600px !important;
  }
}

.motive-component-module__LPaneq__xconvert_header, .motive-component-module__LPaneq__xconvert_footer {
  height: 120px;
}

.file-download-component-module__qhy-lq__help-message {
  color: #fff;
  background-color: rgba(235, 66, 0, .863);
  font-size: 1.4rem;
}

.file-download-component-module__qhy-lq__card .file-download-component-module__qhy-lq__card-img-top {
  width: 100%;
}

.meme-generator-component-module__HarDwW__page-heading .meme-generator-component-module__HarDwW__title {
  margin-top: .2em;
  font-size: 2em;
  font-weight: 400;
  line-height: 2.1rem;
}

.meme-generator-component-module__HarDwW__feature .meme-generator-component-module__HarDwW__title {
  border-bottom: 1px solid;
  font-size: 1.2em;
  font-weight: 700;
}

.meme-generator-component-module__HarDwW__feature .meme-generator-component-module__HarDwW__description {
  margin-bottom: 10px;
}

.meme-generator-component-module__HarDwW__text {
  color: #000;
  font: 40px / 1.2 Impact, Charcoal, sans-serif;
  position: absolute;
  left: 8.2px;
}

.meme-generator-component-module__HarDwW__action-menu {
  margin-bottom: 1rem;
}

.meme-generator-component-module__HarDwW__action-menu button:not(.meme-generator-component-module__HarDwW__float-right) {
  margin-right: .4rem;
}

.meme-generator-component-module__HarDwW__action-menu .meme-generator-component-module__HarDwW__float-right {
  margin-left: 5px;
}

.meme-generator-component-module__HarDwW__canvas-container {
  position: relative;
}

.meme-generator-component-module__HarDwW__canvas-container > * {
  position: absolute;
  left: 0;
}

.meme-generator-component-module__HarDwW__canvas-container .meme-generator-component-module__HarDwW__control-hidden {
  display: none !important;
}

.meme-generator-component-module__HarDwW__canvas-container .meme-generator-component-module__HarDwW__container-invisible {
  background-color: rgba(44, 173, 247, 0) !important;
  border: 0 solid rgba(209, 209, 209, .84) !important;
}

.meme-generator-component-module__HarDwW__canvas-container .meme-generator-component-module__HarDwW__graphc-container:hover {
  background-color: rgba(44, 172, 247, .32);
}

.meme-generator-component-module__HarDwW__canvas-container .meme-generator-component-module__HarDwW__graphic-container-parent {
  z-index: 1;
  border: 1px solid rgba(209, 209, 209, .84);
}

.meme-generator-component-module__HarDwW__canvas-container .meme-generator-component-module__HarDwW__graphic-container-parent .meme-generator-component-module__HarDwW__container-event-handler {
  width: 100%;
  height: 100%;
}

.meme-generator-component-module__HarDwW__canvas-container .meme-generator-component-module__HarDwW__graphic-container-parent .meme-generator-component-module__HarDwW__handler {
  color: #fff;
  cursor: pointer;
  background: rgba(61, 61, 61, .9);
  width: 1.4rem;
  height: 1.4rem;
  margin: .2rem;
  padding: .2rem;
  font-size: 1rem;
  position: absolute;
  right: -1rem;
}

.meme-generator-component-module__HarDwW__canvas-container .meme-generator-component-module__HarDwW__graphic-container-parent .meme-generator-component-module__HarDwW__handler i {
  text-align: center;
  display: block;
}

.meme-generator-component-module__HarDwW__canvas-container .meme-generator-component-module__HarDwW__graphic-container-parent .meme-generator-component-module__HarDwW__handler.meme-generator-component-module__HarDwW__selected {
  background: rgba(175, 42, 42, .9);
}

.meme-generator-component-module__HarDwW__canvas-container .meme-generator-component-module__HarDwW__graphic-container-parent .meme-generator-component-module__HarDwW__handler:hover {
  background: rgba(255, 50, 50, .9);
}

.meme-generator-component-module__HarDwW__canvas-container .meme-generator-component-module__HarDwW__graphic-container-parent .meme-generator-component-module__HarDwW__toggle-edit {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  top: 1.7rem;
}

.meme-generator-component-module__HarDwW__canvas-container .meme-generator-component-module__HarDwW__graphic-container-parent .meme-generator-component-module__HarDwW__delete-text {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  padding: .2rem .3rem;
  top: 3.2rem;
}

.meme-generator-component-module__HarDwW__canvas-container .meme-generator-component-module__HarDwW__graphic-container-parent .meme-generator-component-module__HarDwW__resize-handler {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  top: .2rem;
}

.meme-generator-component-module__HarDwW__canvas-container .meme-generator-component-module__HarDwW__graphic-container-parent .meme-generator-component-module__HarDwW__rotate-handler {
  color: #fff;
  background-color: #fff;
  width: 2px;
  height: 45px;
  margin: 0 calc(50% - 1px);
  position: absolute;
  bottom: -45px;
}

.meme-generator-component-module__HarDwW__canvas-container .meme-generator-component-module__HarDwW__graphic-container-parent .meme-generator-component-module__HarDwW__rotate-handler i.meme-generator-component-module__HarDwW__background {
  color: #f6f6f6;
  font-size: 1.5rem;
  position: relative;
  bottom: -34px;
  left: -9px;
}

.meme-generator-component-module__HarDwW__canvas-container .meme-generator-component-module__HarDwW__graphic-container-parent .meme-generator-component-module__HarDwW__rotate-handler i.meme-generator-component-module__HarDwW__rotate {
  color: #037bff;
  position: relative;
  bottom: -7px;
  left: -6px;
}

.meme-generator-component-module__HarDwW__canvas-container .meme-generator-component-module__HarDwW__text-container-parent {
  z-index: 1;
  border: 1px solid rgba(209, 209, 209, .84);
}

.meme-generator-component-module__HarDwW__canvas-container .meme-generator-component-module__HarDwW__text-container-parent.meme-generator-component-module__HarDwW__editor-shown {
  z-index: 100;
}

.meme-generator-component-module__HarDwW__canvas-container .meme-generator-component-module__HarDwW__text-container-parent .meme-generator-component-module__HarDwW__text-container {
  overflow-wrap: normal;
  white-space: pre-wrap;
  border: 2px solid #673ab7;
  width: 100%;
  height: 40px;
  position: relative;
  top: 0;
  left: 0;
}

.meme-generator-component-module__HarDwW__canvas-container .meme-generator-component-module__HarDwW__text-container-parent .meme-generator-component-module__HarDwW__text-editor.meme-generator-component-module__HarDwW__hidden {
  display: none;
}

.meme-generator-component-module__HarDwW__canvas-container .meme-generator-component-module__HarDwW__text-container-parent .meme-generator-component-module__HarDwW__text-editor {
  background: #f5f5f5;
  width: 450px;
  padding: 5px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.meme-generator-component-module__HarDwW__canvas-container .meme-generator-component-module__HarDwW__text-container-parent .meme-generator-component-module__HarDwW__text-editor .meme-generator-component-module__HarDwW__text-editor-content > * {
  vertical-align: middle;
  margin-bottom: .25rem;
  margin-right: .2rem;
  display: inline-block;
}

.meme-generator-component-module__HarDwW__canvas-container .meme-generator-component-module__HarDwW__text-container-parent .meme-generator-component-module__HarDwW__text-editor .meme-generator-component-module__HarDwW__text-editor-content > div:last-child {
  margin-right: 0;
}

.meme-generator-component-module__HarDwW__canvas-container .meme-generator-component-module__HarDwW__text-container-parent .meme-generator-component-module__HarDwW__text-editor textarea {
  width: 100%;
}

.meme-generator-component-module__HarDwW__canvas-container .meme-generator-component-module__HarDwW__text-container-parent .meme-generator-component-module__HarDwW__text-editor .meme-generator-component-module__HarDwW__editor-panel {
  text-align: right;
  border: 0;
  height: 1.7rem;
  margin: 0;
  padding: 0;
}

.meme-generator-component-module__HarDwW__canvas-container .meme-generator-component-module__HarDwW__text-container-parent .meme-generator-component-module__HarDwW__text-editor .meme-generator-component-module__HarDwW__editor-panel span {
  cursor: pointer;
  border-radius: 0;
  margin-right: .5rem;
}

.meme-generator-component-module__HarDwW__canvas-container .meme-generator-component-module__HarDwW__text-container-parent .meme-generator-component-module__HarDwW__container-event-handler {
  width: 100%;
  height: 100%;
}

.meme-generator-component-module__HarDwW__canvas-container .meme-generator-component-module__HarDwW__text-container-parent .meme-generator-component-module__HarDwW__handler {
  color: #fff;
  cursor: pointer;
  text-align: center;
  background: rgba(61, 61, 61, .9);
  width: 1.4rem;
  height: 1.4rem;
  margin: .2rem;
  font-size: 1rem;
  position: absolute;
  right: -1rem;
}

.meme-generator-component-module__HarDwW__canvas-container .meme-generator-component-module__HarDwW__text-container-parent .meme-generator-component-module__HarDwW__handler i {
  text-align: center;
  display: block;
}

.meme-generator-component-module__HarDwW__canvas-container .meme-generator-component-module__HarDwW__text-container-parent .meme-generator-component-module__HarDwW__handler.meme-generator-component-module__HarDwW__selected {
  background: rgba(175, 42, 42, .9);
}

.meme-generator-component-module__HarDwW__canvas-container .meme-generator-component-module__HarDwW__text-container-parent .meme-generator-component-module__HarDwW__toggle-edit {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  top: 1.7rem;
}

.meme-generator-component-module__HarDwW__canvas-container .meme-generator-component-module__HarDwW__text-container-parent .meme-generator-component-module__HarDwW__delete-text {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  top: 3.2rem;
}

.meme-generator-component-module__HarDwW__canvas-container .meme-generator-component-module__HarDwW__text-container-parent .meme-generator-component-module__HarDwW__resize-handler {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  top: .2rem;
}

.meme-generator-component-module__HarDwW__canvas-container .meme-generator-component-module__HarDwW__image-container {
  opacity: .6;
  width: 100%;
  height: 100%;
}

.meme-generator-component-module__HarDwW__canvas-container .meme-generator-component-module__HarDwW__image-container .meme-generator-component-module__HarDwW__image-handles {
  border: 1px solid #000;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.meme-generator-component-module__HarDwW__canvas-container .meme-generator-component-module__HarDwW__image-container .meme-generator-component-module__HarDwW__image-handles .meme-generator-component-module__HarDwW__image-actions {
  text-align: center;
}

.meme-generator-component-module__HarDwW__canvas-container .meme-generator-component-module__HarDwW__image-container .meme-generator-component-module__HarDwW__image-handles .meme-generator-component-module__HarDwW__image-actions button {
  margin: 1px;
}

.meme-generator-component-module__HarDwW__canvas-container .meme-generator-component-module__HarDwW__image-container:hover {
  opacity: 1;
}

.meme-generator-component-module__HarDwW__canvas-container .meme-generator-component-module__HarDwW__level100 {
  z-index: 100;
}

.meme-generator-component-module__HarDwW__canvas-container .meme-generator-component-module__HarDwW__meme-action-panel {
  background: #000;
  padding: 10px;
}

.meme-generator-component-module__HarDwW__hidden-feature {
  display: none;
}

.meme-generator-component-module__HarDwW__how-to-guide {
  background: #f5f5f5;
  margin-bottom: 2rem;
  padding: 1rem;
}

.meme-generator-component-module__HarDwW__how-to-guide ol {
  margin-left: 0;
  padding-left: 0;
  list-style: none;
}

.file-upload-modal-module__9OqAma__options-container {
  background: #fff;
  padding: 1rem;
}

.file-upload-modal-module__9OqAma__options-container .file-upload-modal-module__9OqAma__option {
  color: #fff;
  cursor: pointer;
  background: #673ab7;
  margin: 1rem;
  padding: 1rem;
  font-size: 1.4rem;
  transition: all .1s ease-in-out;
}

.file-upload-modal-module__9OqAma__options-container .file-upload-modal-module__9OqAma__option:hover {
  color: #fff !important;
  background: #4527a0 !important;
}

.file-upload-modal-module__9OqAma__options-container .file-upload-modal-module__9OqAma__close-btn {
  color: #fff;
  cursor: pointer;
  background: #7b7b7b;
  border-radius: 1rem;
  width: 1.5rem;
  height: 1.5rem;
  padding: 0 .4rem;
  line-height: 1.5rem;
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.file-upload-modal-module__9OqAma__options-container .file-upload-modal-module__9OqAma__close-btn:hover {
  background: #ff7575 !important;
}

.file-upload-modal-module__9OqAma__options-container img.file-upload-modal-module__9OqAma__google-drive-image {
  max-width: 1rem;
  margin-right: .4rem;
}

.file-upload-component-module__R0DRGa__dropdown-item {
  cursor: pointer;
  width: 100%;
  padding: 10px 20px;
}

.file-upload-component-module__R0DRGa__dropdown-item img, .file-upload-component-module__R0DRGa__btn-prefix-icon {
  width: .9rem;
  vertical-align: unset;
  margin-right: .5rem;
}

.file-upload-component-module__R0DRGa__file-upload-box {
  margin-top: 1vh;
  margin-bottom: 0;
}

.file-upload-component-module__R0DRGa__file-upload-box .file-upload-component-module__R0DRGa__file-upload {
  margin-left: 15px;
  margin-right: 15px;
}

.file-upload-component-module__R0DRGa__file-upload-box .file-upload-component-module__R0DRGa__dropdown-container {
  text-align: center;
  display: block;
}

.file-upload-component-module__R0DRGa__file-upload-box .file-upload-component-module__R0DRGa__dropdown-container .file-upload-component-module__R0DRGa__dropdown-toggle:after, .file-upload-component-module__R0DRGa__file-upload-box .file-upload-component-module__R0DRGa__upload-btn-parent input {
  display: none;
}

.file-upload-component-module__R0DRGa__file-upload-box .file-upload-component-module__R0DRGa__upload-btn-parent li {
  cursor: pointer;
}

.file-upload-component-module__R0DRGa__file-upload-box .file-upload-component-module__R0DRGa__upload-btn-parent img.file-upload-component-module__R0DRGa__google-drive-image {
  max-width: 1rem;
  margin-right: .4rem;
}

.file-upload-component-module__R0DRGa__file-upload-box .file-upload-component-module__R0DRGa__upload-btn {
  color: #fff;
  cursor: pointer;
  border-radius: 0;
  width: 50%;
  min-width: 300px;
  padding: 1rem;
  font-size: 1.45rem;
  line-height: 1.5;
}

.file-upload-component-module__R0DRGa__file-upload-box .file-upload-component-module__R0DRGa__upload-btn.file-upload-component-module__R0DRGa__small-upload-btn {
  width: unset;
  min-width: unset;
  padding: .2rem .4rem;
  font-size: .9rem;
}

.file-upload-component-module__R0DRGa__file-upload-box .file-upload-component-module__R0DRGa__fullscreen-file-selector-backdrop {
  z-index: 3;
  background: rgba(0, 0, 0, .48);
  width: 100vw;
  height: 100vh;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

.file-upload-component-module__R0DRGa__file-upload-box .file-upload-component-module__R0DRGa__fullscreen-file-selector-backdrop .file-upload-component-module__R0DRGa__fullscreen-file-selector {
  width: 90vw;
  height: 90vh;
  position: absolute;
  top: 5vh;
  bottom: 5vh;
  left: 5vw;
}

.file-upload-component-module__R0DRGa__file-upload-box .file-upload-component-module__R0DRGa__fullscreen-file-selector-backdrop .file-upload-component-module__R0DRGa__fullscreen-file-selector .file-upload-component-module__R0DRGa__options-container {
  background: #fff;
  border-radius: 1rem;
  padding: 2rem;
}

.file-upload-component-module__R0DRGa__file-upload-box .file-upload-component-module__R0DRGa__fullscreen-file-selector-backdrop .file-upload-component-module__R0DRGa__fullscreen-file-selector .file-upload-component-module__R0DRGa__options-container .file-upload-component-module__R0DRGa__option {
  color: #fff;
  cursor: pointer;
  background: #673ab7;
  margin: 1rem;
  padding: 1rem;
  font-size: 1.4rem;
  transition: all .1s ease-in-out;
}

.file-upload-component-module__R0DRGa__file-upload-box .file-upload-component-module__R0DRGa__fullscreen-file-selector-backdrop .file-upload-component-module__R0DRGa__fullscreen-file-selector .file-upload-component-module__R0DRGa__options-container .file-upload-component-module__R0DRGa__option:hover {
  color: #fff !important;
  background: #4527a0 !important;
}

.file-upload-component-module__R0DRGa__file-upload-box .file-upload-component-module__R0DRGa__fullscreen-file-selector-backdrop .file-upload-component-module__R0DRGa__fullscreen-file-selector .file-upload-component-module__R0DRGa__options-container .file-upload-component-module__R0DRGa__close-btn {
  color: #fff;
  cursor: pointer;
  background: #7b7b7b;
  border-radius: 1rem;
  width: 1.5rem;
  height: 1.5rem;
  padding: 0 .4rem;
  line-height: 1.5rem;
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.file-upload-component-module__R0DRGa__file-upload-box .file-upload-component-module__R0DRGa__fullscreen-file-selector-backdrop .file-upload-component-module__R0DRGa__fullscreen-file-selector .file-upload-component-module__R0DRGa__options-container .file-upload-component-module__R0DRGa__close-btn:hover {
  background: #ff7575 !important;
}

.file-upload-component-module__R0DRGa__upload-btn-group {
  flex-direction: row;
  justify-content: center;
  display: flex;
}

.file-upload-component-module__R0DRGa__upload-btn-group .file-upload-component-module__R0DRGa__file-upload-btn-parent {
  margin-right: 10px;
}

.file-upload-component-module__R0DRGa__upload-btn-group .file-upload-component-module__R0DRGa__file-upload-btn-parent button {
  width: 420px;
  height: 92px;
  font-size: 36px;
  line-height: 30px;
}

.file-upload-component-module__R0DRGa__upload-btn-group .file-upload-component-module__R0DRGa__file-upload-btn-parent button.file-upload-component-module__R0DRGa__full-width-btn {
  width: 100%;
}

.file-upload-component-module__R0DRGa__upload-btn-group .file-upload-component-module__R0DRGa__full-width-upload-btns {
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  width: 100%;
  display: grid;
}

.file-upload-component-module__R0DRGa__upload-btn-group .file-upload-component-module__R0DRGa__full-width-upload-btns button {
  border-radius: 50%;
  font-size: 36px;
  line-height: 30px;
}

.file-upload-component-module__R0DRGa__upload-btn-group .file-upload-component-module__R0DRGa__full-width-upload-btns button.file-upload-component-module__R0DRGa__full-width-btn {
  border-radius: 50%;
  grid-area: 1 / 1 / auto / 3;
  width: 150px;
  height: 150px;
  font-size: 1rem;
}

.file-upload-component-module__R0DRGa__upload-btn-group .file-upload-component-module__R0DRGa__full-width-upload-btns button.file-upload-component-module__R0DRGa__full-width-btn img {
  width: 20px;
}

.file-upload-component-module__R0DRGa__upload-btn-group .file-upload-component-module__R0DRGa__other-upload-btns {
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
  display: flex;
}

.file-upload-component-module__R0DRGa__upload-btn-group .file-upload-component-module__R0DRGa__other-upload-btns button {
  border-radius: 100%;
  width: 42px;
  height: 42px;
  font-weight: bold;
}

.file-upload-component-module__R0DRGa__upload-btn-group .file-upload-component-module__R0DRGa__other-upload-btns button img {
  max-width: 1.1rem;
  max-height: 1.1rem;
}

@media only screen and (max-width: 480px) {
  .file-upload-component-module__R0DRGa__upload-btn-group {
    flex-direction: column;
  }

  .file-upload-component-module__R0DRGa__upload-btn-group .file-upload-component-module__R0DRGa__file-upload-btn-parent {
    margin-bottom: 10px;
    margin-right: 0;
  }

  .file-upload-component-module__R0DRGa__upload-btn-group .file-upload-component-module__R0DRGa__file-upload-btn-parent button {
    width: 100%;
  }

  .file-upload-component-module__R0DRGa__upload-btn-group .file-upload-component-module__R0DRGa__other-upload-btns {
    flex-direction: row;
    justify-content: flex-start;
    gap: 10px;
    display: flex;
  }

  .file-upload-component-module__R0DRGa__upload-btn-group .file-upload-component-module__R0DRGa__other-upload-btns.file-upload-component-module__R0DRGa__vertical {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.image-resize-root-component-module__o3IYQW__page-heading {
  margin-bottom: 1rem;
}

.image-resize-root-component-module__o3IYQW__page-heading .image-resize-root-component-module__o3IYQW__title {
  margin-top: .2em;
  font-size: 2em;
  font-weight: 400;
  line-height: 2.1rem;
}

.image-resize-root-component-module__o3IYQW__feature .image-resize-root-component-module__o3IYQW__title {
  border-bottom: 1px solid;
  font-size: 1.2em;
  font-weight: 700;
}

.image-resize-root-component-module__o3IYQW__feature .image-resize-root-component-module__o3IYQW__description {
  margin-bottom: 10px;
}

.image-resize-root-component-module__o3IYQW__upload-container {
  margin-bottom: 1rem;
}

.image-resize-root-component-module__o3IYQW__how-to-guide {
  background: #f5f5f5;
  margin-bottom: 2rem;
  padding: 1rem;
}

.image-resize-root-component-module__o3IYQW__how-to-guide ol {
  margin-left: 0;
  padding-left: 0;
  list-style: none;
}

.image-resize-root-component-module__o3IYQW__image-resize-root {
  background-color: var(--orange-light);
}

.photo-editor-component-module__puLehq__y {
  display: none;
}

.photo-editor-component-module__puLehq__tab-pane {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.photo-editor-component-module__puLehq__single-row-tab-content {
  flex-direction: row;
  justify-content: space-between;
  display: flex;
}

.photo-editor-component-module__puLehq__single-row-tab-content .photo-editor-component-module__puLehq__single-row-buttons {
  flex-direction: row;
  flex-grow: 1;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  display: flex;
}

.photo-editor-component-module__puLehq__resize-tab-content {
  flex-direction: column;
  gap: 10px;
  display: flex;
}

.photo-editor-component-module__puLehq__resize-tab-content .photo-editor-component-module__puLehq__resize-options {
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 15px;
  display: flex;
}

.photo-editor-component-module__puLehq__resize-tab-content .photo-editor-component-module__puLehq__resize-options .photo-editor-component-module__puLehq__preset-sizes {
  grid-gap: 4px;
  flex-flow: row;
  align-items: flex-start;
  gap: 4px;
  display: flex;
}

.photo-editor-component-module__puLehq__resize-tab-content .photo-editor-component-module__puLehq__resize-options .photo-editor-component-module__puLehq__preset-sizes button {
  height: 32px;
}

.photo-editor-component-module__puLehq__resize-tab-content .photo-editor-component-module__puLehq__apply-and-save-btns {
  flex-direction: row;
  justify-content: space-between;
  display: flex;
}

.photo-editor-component-module__puLehq__resize-tab-content .photo-editor-component-module__puLehq__apply-and-save-btns .photo-editor-component-module__puLehq__apply-btns {
  flex-direction: row;
  gap: 10px;
  display: flex;
}

.photo-editor-component-module__puLehq__col-lg-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.photo-editor-component-module__puLehq__row {
  flex-wrap: wrap;
  display: flex;
}

.photo-editor-component-module__puLehq__container {
  text-align: center;
  touch-action: none;
  background-color: rgba(205, 205, 205, .87);
  background-size: contain;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  position: relative;
}

.photo-editor-component-module__puLehq__container.photo-editor-component-module__puLehq__imageOnly canvas, .photo-editor-component-module__puLehq__container.photo-editor-component-module__puLehq__canvasOnly img.photo-editor-component-module__puLehq__image {
  display: none !important;
}

.photo-editor-component-module__puLehq__container.photo-editor-component-module__puLehq__hideImageAndCanvas img.photo-editor-component-module__puLehq__image, .photo-editor-component-module__puLehq__container.photo-editor-component-module__puLehq__hideImageAndCanvas canvas {
  display: none;
}

.photo-editor-component-module__puLehq__container img.photo-editor-component-module__puLehq__image, .photo-editor-component-module__puLehq__container canvas {
  max-height: calc(100vh - 320px);
  position: absolute;
  top: 0;
  left: 0;
}

.photo-editor-component-module__puLehq__container canvas.photo-editor-component-module__puLehq__x, .photo-editor-component-module__puLehq__container canvas.photo-editor-component-module__puLehq__y {
  max-width: 100%;
  max-height: calc(100vh - 320px);
  position: absolute;
  top: 0;
  left: 0;
}

.photo-editor-component-module__puLehq__container canvas.photo-editor-component-module__puLehq__x.photo-editor-component-module__puLehq__relative, .photo-editor-component-module__puLehq__container canvas.photo-editor-component-module__puLehq__y.photo-editor-component-module__puLehq__relative {
  position: relative;
  top: 0;
  left: 0;
  max-width: 100% !important;
  max-height: 100% !important;
}

@media (min-width: 768px) {
  .photo-editor-component-module__puLehq__container img.photo-editor-component-module__puLehq__image, .photo-editor-component-module__puLehq__container canvas {
    max-width: 100%;
    max-height: calc(100vh - 320px);
  }
}

.photo-editor-component-module__puLehq__container canvas.photo-editor-component-module__puLehq__overlayCanvas, .photo-editor-component-module__puLehq__container canvas#photo-editor-component-module__puLehq__overlayCanvas {
  max-width: 100%;
  max-height: 100%;
  display: none;
}

.photo-editor-component-module__puLehq__container .photo-editor-component-module__puLehq__handle {
  z-index: 1;
}

.photo-editor-component-module__puLehq__content-holder {
  width: 100vw;
  max-width: 100%;
  margin: 0 auto;
  height: calc(100vh - 300px) !important;
}

.photo-editor-component-module__puLehq__canvasAndImageHolderParent {
  width: 100vw;
  height: calc(100vh - 320px);
  max-height: calc(100vh - 320px);
}

.photo-editor-component-module__puLehq__noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -khtml-user-select: none;
}

img.photo-editor-component-module__puLehq__image {
  -o-transition: all .3s ease;
  transition: all .3s;
}

#photo-editor-component-module__puLehq__adjust .photo-editor-component-module__puLehq__row {
  width: 100%;
  display: inline-flex;
}

#photo-editor-component-module__puLehq__adjust .photo-editor-component-module__puLehq__row .photo-editor-component-module__puLehq__slider {
  width: 20%;
  min-width: 100%;
  margin: 10px;
}

#photo-editor-component-module__puLehq__file_input {
  margin: 0 auto;
}

.photo-editor-component-module__puLehq__filter-type {
  overflow-x: auto;
  overflow-y: none;
}

#photo-editor-component-module__puLehq__sample_holder {
  display: flex;
}

#photo-editor-component-module__puLehq__sample_holder > div {
  margin: 5px 4px 0 0;
}

.photo-editor-component-module__puLehq__loader_parent {
  z-index: 20;
  background-color: rgba(0, 0, 0, .75);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.photo-editor-component-module__puLehq__loader {
  border: 5px solid #f3f3f3;
  border-top-color: #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin: 0 auto;
  animation: 2s linear infinite photo-editor-component-module__puLehq__spin;
  position: relative;
  top: calc(50% - 25px);
}

.photo-editor-component-module__puLehq__filter-type::-webkit-scrollbar-track {
  background-color: #f5f5f5;
  border-radius: 0;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
}

.photo-editor-component-module__puLehq__filter-type::-webkit-scrollbar {
  background-color: #f5f5f5;
  width: 15px;
  height: 15px;
}

.photo-editor-component-module__puLehq__filter-type::-webkit-scrollbar-thumb {
  background-color: #757575;
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
}

@keyframes photo-editor-component-module__puLehq__spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.photo-editor-component-module__puLehq__elementResizable {
  cursor: move;
  z-index: 2;
  background-color: rgba(255, 255, 255, 0);
  background-size: 100% 100%;
  width: calc(50% - 200px);
  height: calc(50% - 200px);
  display: none;
  position: absolute;
  top: calc(50% - 200px);
  left: calc(50% - 200px);
  box-shadow: 0 0 28px #000;
}

div.photo-editor-component-module__puLehq__elementResizable .photo-editor-component-module__puLehq__row {
  width: 100%;
  height: 100%;
  margin: 0;
}

div.photo-editor-component-module__puLehq__elementResizable .photo-editor-component-module__puLehq__grid {
  border: 1px solid #f6f6f6;
  width: calc(100% + 1px);
  height: calc(100% + 1px);
  position: absolute;
  top: -.5px;
  left: -.5px;
}

.photo-editor-component-module__puLehq__content-centered > * {
  margin: auto;
  position: relative;
}

.photo-editor-component-module__puLehq__border-right-1px {
  border-right: 1px solid rgba(255, 255, 255, .3);
}

.photo-editor-component-module__puLehq__border-bottom-1px {
  border-bottom: 1px solid rgba(255, 255, 255, .3);
}

div.photo-editor-component-module__puLehq__elementResizable .photo-editor-component-module__puLehq__overlay {
  z-index: 30;
  position: absolute;
}

div.photo-editor-component-module__puLehq__elementResizable .photo-editor-component-module__puLehq__row div {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

div.photo-editor-component-module__puLehq__elementResizable .photo-editor-component-module__puLehq__row div div {
  float: left;
  border-top: 1px solid rgba(255, 255, 255, .3);
  border-left: 1px solid rgba(255, 255, 255, .3);
  width: 33.33%;
  height: 33.33%;
  margin: 0;
}

.photo-editor-component-module__puLehq__nwgrip, .photo-editor-component-module__puLehq__negrip, .photo-editor-component-module__puLehq__swgrip, .photo-editor-component-module__puLehq__segrip, .photo-editor-component-module__puLehq__ngrip, .photo-editor-component-module__puLehq__egrip, .photo-editor-component-module__puLehq__sgrip, .photo-editor-component-module__puLehq__wgrip {
  z-index: 40;
  background-color: #ccc;
  border: 3px solid #fff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  position: absolute;
}

.photo-editor-component-module__puLehq__nwgrip {
  cursor: nwse-resize;
  top: -10px;
  left: -10px;
}

.photo-editor-component-module__puLehq__negrip {
  cursor: nesw-resize;
  top: -10px;
  right: -10px;
}

.photo-editor-component-module__puLehq__swgrip {
  cursor: nesw-resize;
  bottom: -10px;
  left: -10px;
}

.photo-editor-component-module__puLehq__segrip {
  cursor: nwse-resize;
  bottom: -10px;
  right: -10px;
}

.photo-editor-component-module__puLehq__ngrip {
  cursor: n-resize;
  top: -10px;
  left: calc(50% - 10px);
}

.photo-editor-component-module__puLehq__sgrip {
  cursor: s-resize;
  bottom: -10px;
  left: calc(50% - 10px);
}

.photo-editor-component-module__puLehq__wgrip {
  cursor: w-resize;
  top: calc(50% - 10px);
  left: -10px;
}

.photo-editor-component-module__puLehq__egrip {
  cursor: e-resize;
  top: calc(50% - 10px);
  right: -10px;
}

.photo-editor-component-module__puLehq__no-events {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  pointer-events: none;
  touch-action: none;
}

.photo-editor-component-module__puLehq__menu {
  text-align: right;
}

.photo-editor-component-module__puLehq__menu .photo-editor-component-module__puLehq__menu-item {
  text-align: left;
  width: 7rem;
  margin-right: 1rem;
  display: inline-block;
}

.photo-editor-component-module__puLehq__nav-container {
  z-index: 2;
  padding-top: 10px;
  position: relative;
  background: rgba(232, 232, 232, .38) !important;
}

.photo-editor-component-module__puLehq__nav-container button, .photo-editor-component-module__puLehq__nav-container input, .photo-editor-component-module__puLehq__nav-container li, .photo-editor-component-module__puLehq__nav-container selectable-dropdown, .photo-editor-component-module__puLehq__nav-container .photo-editor-component-module__puLehq__selectable {
  user-select: initial;
  pointer-events: initial;
  touch-action: initial;
}

.photo-editor-component-module__puLehq__nav-container .photo-editor-component-module__puLehq__tab-content {
  background-color: rgba(255, 255, 255, 0) !important;
}

.photo-editor-component-module__puLehq__nav-container .photo-editor-component-module__puLehq__nav-tabs {
  color: #000;
  background: rgba(255, 255, 255, 0) !important;
}

.photo-editor-component-module__puLehq__nav-container .photo-editor-component-module__puLehq__nav-tabs .photo-editor-component-module__puLehq__nav-tabs > li > a {
  color: #007bff !important;
}

.photo-editor-component-module__puLehq__nav-container .photo-editor-component-module__puLehq__fullscreen {
  margin-right: 1rem;
  position: absolute;
  right: 0;
}

.photo-editor-component-module__puLehq__tools-container {
  z-index: 3;
  background: rgba(255, 255, 255, .667);
  min-height: 200px;
  font-size: 1rem;
  position: relative;
}

.photo-editor-component-module__puLehq__tools-container .photo-editor-component-module__puLehq__start-new-btn {
  z-index: 1;
  position: absolute;
  top: 5px;
  right: 5px;
}

.photo-editor-component-module__puLehq__content-holder-parent {
  z-index: 1;
  position: relative;
}

.photo-editor-component-module__puLehq__content-holder-parent .photo-editor-component-module__puLehq__upload-box {
  position: relative;
}

.photo-editor-component-module__puLehq__content-holder-parent .photo-editor-component-module__puLehq__upload-box button {
  margin: 0 auto;
  display: block;
  position: relative;
}

.photo-editor-component-module__puLehq__tab-content {
  text-align: center;
  color: #fff;
  background-color: #3a3a3a;
  min-height: 10px;
  margin: 0 0 10px;
}

.photo-editor-component-module__puLehq__tab-content > .photo-editor-component-module__puLehq__active {
  padding: 10px;
}

.photo-editor-component-module__puLehq__btn {
  border-radius: 0 !important;
}

#photo-editor-component-module__puLehq__crop_panel .photo-editor-component-module__puLehq__width, #photo-editor-component-module__puLehq__crop_panel .photo-editor-component-module__puLehq__height {
  color: #000;
  width: 60px;
}

#photo-editor-component-module__puLehq__rotate i, #photo-editor-component-module__puLehq__flip i {
  margin-right: 10px;
}

#photo-editor-component-module__puLehq__flip .photo-editor-component-module__puLehq__vertical i {
  transform: rotate(90deg);
}

.photo-editor-component-module__puLehq__file_upload_label {
  width: 100%;
}

.photo-editor-component-module__puLehq__alert {
  margin-bottom: 0;
  padding: 5px;
}

.photo-editor-component-module__puLehq__btn.photo-editor-component-module__puLehq__download {
  padding: 0 12px;
}

.photo-editor-component-module__puLehq__btn.photo-editor-component-module__puLehq__download:focus {
  outline: 5px auto #fff;
}

.photo-editor-component-module__puLehq__btn.photo-editor-component-module__puLehq__focus, .photo-editor-component-module__puLehq__btn:focus, .photo-editor-component-module__puLehq__btn:hover {
  color: #fff;
}

.photo-editor-component-module__puLehq__dropdown-menu {
  border-radius: 0;
  left: 35px;
}

.photo-editor-component-module__puLehq__animate {
  animation-name: photo-editor-component-module__puLehq__bounce;
  animation-duration: 1s;
  animation-fill-mode: both;
}

@keyframes photo-editor-component-module__puLehq__bounce {
  from {
    animation-timing-function: ease-out;
    top: 0;
  }

  25% {
    animation-timing-function: ease-in;
    top: 10px;
  }

  50% {
    animation-timing-function: ease-out;
    top: -5px;
  }

  75% {
    animation-timing-function: ease-in;
    top: 15px;
  }

  to {
    top: 0;
  }
}

.resize-image-download-component-module__rnMBmG__small-btn button {
  height: 32px;
}

.tools-component-module___4Vp5G__sections {
  flex-direction: column;
  gap: 15px;
  display: flex;
}

.tools-component-module___4Vp5G__sections h2 {
  text-align: center;
}

.tools-component-module___4Vp5G__sections .tools-component-module___4Vp5G__all-features .tools-component-module___4Vp5G__feature-categories {
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-items: stretch;
  gap: 10px;
  font-size: 1rem;
  display: grid;
  overflow: hidden;
}

.tools-component-module___4Vp5G__sections .tools-component-module___4Vp5G__all-features .tools-component-module___4Vp5G__feature-categories .tools-component-module___4Vp5G__feature-category {
  border: 1px solid var(--gray-dark);
  background: var(--gray);
  border-radius: 4px;
  justify-content: space-between;
  padding: 10px;
  display: flex;
}

.tools-component-module___4Vp5G__sections .tools-component-module___4Vp5G__all-features .tools-component-module___4Vp5G__feature-categories .tools-component-module___4Vp5G__feature-category .tools-component-module___4Vp5G__feature-category-title {
  cursor: pointer;
  color: var(--primary-bg-color);
  font-size: 1.2rem;
  font-weight: bold;
  text-decoration: none;
}

.tools-component-module___4Vp5G__sections .tools-component-module___4Vp5G__all-features .tools-component-module___4Vp5G__feature-categories .tools-component-module___4Vp5G__feature-category .tools-component-module___4Vp5G__feature-category-content {
  cursor: pointer;
  color: var(--gray-darker);
  border: 1px solid var(--gray-darker);
  border-radius: 4px;
  align-items: center;
  padding: 0 10px;
  font-size: .9rem;
  text-decoration: none;
  display: flex;
}

.tools-component-module___4Vp5G__sections .tools-component-module___4Vp5G__all-features .tools-component-module___4Vp5G__feature-subcategories {
  flex-flow: wrap;
  gap: 10px;
  margin-top: 10px;
  display: flex;
}

.tools-component-module___4Vp5G__sections .tools-component-module___4Vp5G__all-features .tools-component-module___4Vp5G__feature-subcategories .tools-component-module___4Vp5G__feature-subcategory {
  color: var(--gray-darker);
  border: 1px solid var(--gray-darker);
  cursor: pointer;
  background: #fff;
  border-radius: 20px;
  padding: 1px 12px;
  font-size: .9rem;
  text-decoration: none;
}

.tools-component-module___4Vp5G__sections .tools-component-module___4Vp5G__featured-items {
  grid-template-columns: 1fr 1fr 1fr;
  align-items: stretch;
  gap: 10px;
  font-size: 1rem;
  display: grid;
  overflow: hidden;
}

.tools-component-module___4Vp5G__sections .tools-component-module___4Vp5G__featured-items .tools-component-module___4Vp5G__featured-item {
  border: 1px solid var(--gray-dark);
  background: var(--gray);
  border-radius: 4px;
  padding: 10px;
}

.tools-component-module___4Vp5G__sections .tools-component-module___4Vp5G__featured-items .tools-component-module___4Vp5G__featured-item .tools-component-module___4Vp5G__featured-item-title {
  color: var(--primary-bg-color);
  font-weight: bold;
  text-decoration: none;
}

@media screen and (max-width: 992px) {
  .tools-component-module___4Vp5G__sections .tools-component-module___4Vp5G__all-features .tools-component-module___4Vp5G__feature-categories {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .tools-component-module___4Vp5G__sections .tools-component-module___4Vp5G__most-used-tools .tools-component-module___4Vp5G__featured-items {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 768px) {
  .tools-component-module___4Vp5G__sections .tools-component-module___4Vp5G__all-features .tools-component-module___4Vp5G__feature-categories {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 576px) {
  .tools-component-module___4Vp5G__sections .tools-component-module___4Vp5G__all-features .tools-component-module___4Vp5G__feature-categories, .tools-component-module___4Vp5G__sections .tools-component-module___4Vp5G__most-used-tools .tools-component-module___4Vp5G__featured-items {
    grid-template-columns: 1fr;
  }
}

.av-cutter-component-module__OAa1aG__cutter-container {
  background-color: var(--orange-light);
  flex-direction: column;
  margin: 10px;
  font-size: .95rem;
  display: flex;
}

.av-cutter-component-module__OAa1aG__cutter-container .av-cutter-component-module__OAa1aG__file-upload-btns {
  margin: 0 15px;
}

.av-cutter-component-module__OAa1aG__cutter-container .av-cutter-component-module__OAa1aG__editor {
  flex-direction: row;
  display: flex;
}

.av-cutter-component-module__OAa1aG__cutter-container .av-cutter-component-module__OAa1aG__editor .av-cutter-component-module__OAa1aG__video-and-stat-container {
  width: 450px;
  max-height: calc(100vh - 220px);
  overflow-y: auto;
}

.av-cutter-component-module__OAa1aG__cutter-container .av-cutter-component-module__OAa1aG__editor .av-cutter-component-module__OAa1aG__video-and-stat-container .av-cutter-component-module__OAa1aG__video-container {
  height: 220px;
}

.av-cutter-component-module__OAa1aG__cutter-container .av-cutter-component-module__OAa1aG__editor .av-cutter-component-module__OAa1aG__video-and-stat-container .av-cutter-component-module__OAa1aG__video-container video {
  width: 100%;
  height: 200px;
}

.av-cutter-component-module__OAa1aG__cutter-container .av-cutter-component-module__OAa1aG__editor .av-cutter-component-module__OAa1aG__video-and-stat-container .av-cutter-component-module__OAa1aG__apply-current-range-btns {
  flex-direction: row;
  gap: 5px;
  display: flex;
}

.av-cutter-component-module__OAa1aG__cutter-container .av-cutter-component-module__OAa1aG__editor .av-cutter-component-module__OAa1aG__tool-container {
  width: calc(100vw - 450px);
  max-height: calc(100vh - 220px);
  padding: 0 15px;
  overflow-y: auto;
}

.av-cutter-component-module__OAa1aG__cutter-container .av-cutter-component-module__OAa1aG__editor .av-cutter-component-module__OAa1aG__tool-container .av-cutter-component-module__OAa1aG__video-seek-buttons {
  gap: 5px;
  display: flex;
}

.av-cutter-component-module__OAa1aG__cutter-container .av-cutter-component-module__OAa1aG__editor .av-cutter-component-module__OAa1aG__tool-container .av-cutter-component-module__OAa1aG__save-result {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  display: flex;
}

.av-cutter-component-module__OAa1aG__cutter-container .av-cutter-component-module__OAa1aG__editor .av-cutter-component-module__OAa1aG__tool-container .av-cutter-component-module__OAa1aG__saved-files {
  flex-direction: column;
  gap: 10px;
  display: flex;
}

.av-cutter-component-module__OAa1aG__cutter-container .av-cutter-component-module__OAa1aG__editor > * {
  color: #404040;
  background: #fbfbfb;
  border: 1px solid #e2e2e2;
  border-radius: 4px;
  margin: .2rem;
  padding: .4rem;
}

@media screen and (max-width: 850px) {
  .av-cutter-component-module__OAa1aG__cutter-container .av-cutter-component-module__OAa1aG__editor .av-cutter-component-module__OAa1aG__video-and-stat-container {
    width: 40vw;
  }

  .av-cutter-component-module__OAa1aG__cutter-container .av-cutter-component-module__OAa1aG__editor .av-cutter-component-module__OAa1aG__tool-container {
    width: 60vw;
  }
}

@media screen and (max-width: 650px) {
  .av-cutter-component-module__OAa1aG__cutter-container .av-cutter-component-module__OAa1aG__editor {
    flex-direction: column;
  }

  .av-cutter-component-module__OAa1aG__cutter-container .av-cutter-component-module__OAa1aG__editor .av-cutter-component-module__OAa1aG__video-and-stat-container {
    width: unset;
  }

  .av-cutter-component-module__OAa1aG__cutter-container .av-cutter-component-module__OAa1aG__editor .av-cutter-component-module__OAa1aG__tool-container {
    width: unset;
    max-height: unset;
    padding: .4rem;
  }
}

.av-cutter-component-module__OAa1aG__cutter-container .av-cutter-component-module__OAa1aG__page-heading .av-cutter-component-module__OAa1aG__title {
  margin-top: .2em;
  font-size: 2em;
  font-weight: 400;
  line-height: 2.1rem;
}

.av-cutter-component-module__OAa1aG__cutter-container .av-cutter-component-module__OAa1aG__feature .av-cutter-component-module__OAa1aG__title {
  border-bottom: 1px solid;
  font-size: 1.2em;
  font-weight: 700;
}

.av-cutter-component-module__OAa1aG__cutter-container .av-cutter-component-module__OAa1aG__feature .av-cutter-component-module__OAa1aG__description {
  margin-bottom: 10px;
}

.av-cutter-component-module__OAa1aG__cutter-container .av-cutter-component-module__OAa1aG__feature ol {
  padding-left: 1rem;
}

.av-cutter-component-module__OAa1aG__cutter-container .av-cutter-component-module__OAa1aG__how-to-guide {
  background: #f5f5f5;
  margin-bottom: 2rem;
  padding: 1rem;
}

.av-cutter-component-module__OAa1aG__cutter-container .av-cutter-component-module__OAa1aG__how-to-guide ol {
  margin-left: 0;
  padding-left: 0;
  list-style: none;
}

.av-cutter-component-module__OAa1aG__cutter-container .av-cutter-component-module__OAa1aG__product-review {
  text-align: center;
  background: #fff;
}

.av-cutter-component-module__OAa1aG__cutter-container .av-cutter-component-module__OAa1aG__product-review .av-cutter-component-module__OAa1aG__text {
  font-size: 1.4rem;
}

.av-cutter-component-module__OAa1aG__cutter-container .av-cutter-component-module__OAa1aG__product-review m-svg {
  cursor: pointer;
}

.av-cutter-component-module__OAa1aG__cutter-container .av-cutter-component-module__OAa1aG__live-file-processor-container {
  z-index: 2;
  background: #3f51b5;
  width: 30px;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
}

.av-cutter-component-module__OAa1aG__cutter-container .av-cutter-component-module__OAa1aG__live-file-processor-container live-file-processor {
  display: none;
  overflow-y: auto;
}

.av-cutter-component-module__OAa1aG__cutter-container .av-cutter-component-module__OAa1aG__live-file-processor-container .av-cutter-component-module__OAa1aG__handler {
  color: #fff;
  cursor: pointer;
  width: 30px;
  height: 100vh;
  position: absolute;
  top: 0;
  bottom: 0;
}

.av-cutter-component-module__OAa1aG__cutter-container .av-cutter-component-module__OAa1aG__live-file-processor-container .av-cutter-component-module__OAa1aG__handler .av-cutter-component-module__OAa1aG__handle {
  text-align: center;
  width: 30px;
  font-size: 2rem;
  font-weight: bold;
  display: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.av-cutter-component-module__OAa1aG__cutter-container .av-cutter-component-module__OAa1aG__live-file-processor-container .av-cutter-component-module__OAa1aG__handler .av-cutter-component-module__OAa1aG__open-handle {
  display: block;
}

.av-cutter-component-module__OAa1aG__cutter-container .av-cutter-component-module__OAa1aG__live-file-processor-container .av-cutter-component-module__OAa1aG__handler-text {
  color: #fff;
  transform-origin: 0;
  white-space: nowrap;
  padding-left: 10px;
  display: block;
  position: absolute;
  left: 15px;
  transform: rotate(90deg);
}

.av-cutter-component-module__OAa1aG__cutter-container .av-cutter-component-module__OAa1aG__live-file-processor-container.av-cutter-component-module__OAa1aG__extended {
  width: 400px;
  min-width: 300px;
}

.av-cutter-component-module__OAa1aG__cutter-container .av-cutter-component-module__OAa1aG__live-file-processor-container.av-cutter-component-module__OAa1aG__extended live-file-processor {
  background: #fff;
  width: calc(100% - 30px);
  height: 100vh;
  display: block;
  position: relative;
  left: 30px;
}

.av-cutter-component-module__OAa1aG__cutter-container .av-cutter-component-module__OAa1aG__live-file-processor-container.av-cutter-component-module__OAa1aG__extended .av-cutter-component-module__OAa1aG__close-handle {
  display: block !important;
}

.av-cutter-component-module__OAa1aG__cutter-container .av-cutter-component-module__OAa1aG__live-file-processor-container.av-cutter-component-module__OAa1aG__extended .av-cutter-component-module__OAa1aG__open-handle {
  display: none !important;
}

.av-cutter-component-module__OAa1aG__cutter-container thead tr {
  background: #b7b7b7;
}

.av-cutter-component-module__OAa1aG__cutter-container tbody tr:nth-child(2n) {
  background-color: #f2f2f2;
}

